import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Save Time With These #5 Communication Work Tips",
  "author": "tzookb",
  "type": "post",
  "date": "2018-09-09T11:46:37.000Z",
  "url": "/2018/09/save-time-with-these-5-communication-work-tips/",
  "categories": ["communication"],
  "tags": ["communication", "email", "management", "slack"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You have 277 unread messages and 115 unread emails.`}</p>
    <p>{`This is how I start my Mondays every week on average.`}</p>
    <p>{`I know those numbers could vary depending on your position or role but we all can agree communication has totally increased.`}</p>
    <p>{`Messages could vary from server errors, sales reports, daily summaries, work tickets, comments from others, mentions, memes of cats and full pledged conversions on the #general channel.`}</p>
    <p>{`There is really not much that you can do with the above, well except of defining rules in your email box (whole other topic, comment if you want to learn more). Another option is to educate your team members about communication.`}</p>
    <h3>{`Generally, there are two major types of communication`}</h3>
    <h4>{`Asynchronous (send and forget)`}</h4>
    <p>{`This is mainly emails, ticket commenting and sending full documented messages on slack or any other messaging tools.`}</p>
    <h4>{`Synchronous (send and wait)`}</h4>
    <p>{`Those messages are something you wait for a response now. This could be direct messages, SMS, phone calls etc.`}</p>
    <h3>{`When should you use each one?`}</h3>
    <p>{`As a rule of thumb, default to “Asynchronous” messaging unless this is something really urgent that you need an immediate response. Always remember that direct messages interrupt the other person work so be sure that it`}{`’`}{`s really worth it.`}</p>
    <p>{`Items that could be worth the interruption:`}</p>
    <ul>
      <li parentName="ul">{`critical server errors`}</li>
      <li parentName="ul">{`a non-working sales page`}</li>
      <li parentName="ul">{`legal stuff`}</li>
      <li parentName="ul">{`task removal/change`}</li>
    </ul>
    <h3>{`The #5 Magic Tips:`}</h3>
    <h5>{`Synchronous only if it`}{`’`}{`s really urgent`}</h5>
    <p>{`Had to mention this again. Default to asynchronous messages and only go to synchronous messages in urgent cases.`}</p>
    <h5>{`no “chit-chat”`}</h5>
    <p>{`I’m not saying don’t be polite! But if you need something don’t do this time waste flow:`}</p>
    <p><strong parentName="p">{`John:`}</strong>{` Hey, how are you?`}</p>
    <p>{`(2 hours later)`}</p>
    <p><strong parentName="p">{`Jane:`}</strong>{` Good and you?`}</p>
    <p><strong parentName="p">{`John:`}</strong>{` Great, can you please do !@$%@T%^ @?`}</p>
    <p><strong parentName="p">{`Jane:`}</strong>{` I`}{`’`}{`m out of the office will look at it later`}</p>
    <p>{`I get a lot of those messages and I mostly ignore them at first as I’m working and I need to focus. But if I’ll get relevant data first I could decide if I should respond or I can wait.`}</p>
    <p>{`How you should do it =>`}</p>
    <p><strong parentName="p">{`John:`}</strong>{` Hey, how are you? can you please do !@$%@T%^ @?`}</p>
    <p>{`(Jane seed the message and decides its urgent so she responds)`}</p>
    <p><strong parentName="p">{`Jane:`}</strong>{` Great thanks 🙂 doing it asap`}</p>
    <p><strong parentName="p">{`John:`}</strong>{` Thanks!`}</p>
    <p><strong parentName="p">{`Jane:`}</strong>{` done 🙂`}</p>
    <h5>{`don’t forget emails`}</h5>
    <p>{`In the world of Slack and instant messaging, we almost forgot emails at the workplace. Emails are a powerful tool. They provide a sense of less urgency and more thoughtful thinking.`}</p>
    <h5>{`be descriptive (stop links with no desc)`}</h5>
    <p>{`Whenever you are sharing links, doesn’t matter if it’s work related or just some funny youtube video, please describe it in a tiny sentence. I can get a lot of messages with links and I don’t really have time to open all of them. If I could see a small sentence like:`}</p>
    <p>{`“funny cat programming”`}</p>
    <p>{`“sales report of August”`}</p>
    <p>{`I could know when and if I should open that link.`}</p>
    <h5>{`be cordial`}</h5>
    <p>{`As you understand from the above, I wrote this post from personal experience and pain. But always be sure to be nice and be cordial 🙂`}</p>
    <p>{`You got an annoying message, don’t respond right away! go have a cup of cold water, relax and then respond properly. NO CAPITALIZED LETTERS and no exclamation marks!!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      